
<template>
  <div id="user-edit-tab-info">
    <h6 class="mb-3 text-uppercase bg-light p-2">
      <i class="mdi mdi-account-circle mr-1"></i> Other Info
    </h6>
    <div class="row gy-3 gx-3">
      <div class="col-md-6">
        <div class="form-group">
            <label class="d-block">Date of Birth</label>
            <flat-pickr
              v-model="data.dob"
              :config="{wrap: true, 
                altFormat: 'M j, Y',
                altInput: true,
                dateFormat: 'd M, Y',
                maxDate: new Date()
              }"
              class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Country</label>
          <multiselect 
          mode="single"
          searchable
          valueProp="id"
          trackBy="name" label="name"
          :options="countries" 
          v-model="data.country_id" placeholder="--select--"></multiselect>
        </div>
      </div>

      <div class="col-12">
        <label for="userbio">About</label>
        <textarea id="userbio"  v-model="data.about" class="form-control"
          rows="3" placeholder="Write something..." name="about"></textarea>
      </div>
    </div>
    <div class="row mt-3 justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import flatPickr from "vue-flatpickr-component";
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    flatPickr,
    Multiselect,
  },
  data() {
    return {
      data:{
        about: "",
        dob: null,
        gender: "",
        country_id: null
      },
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    updateUser(){
      const formData = new FormData()
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post("/account/update-info",formData)
      .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit('UPDATE_USER_INFO', response.data.data)
          }
      })
    },
    patchUser(){
      this.data = this.$filters.patchUpdatables(this.data, this.activeUser)
      this.data.dob = this.data.dob ? new Date(this.data.dob) : null
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
